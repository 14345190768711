






























import Vue from 'vue';

export default Vue.extend({
  components: {
    PolicyV1: () => import(/* webpackChunkName: "l-dse-v1" */ '@/components/legal/dse/V1.vue'),
    PolicyV2: () => import(/* webpackChunkName: "l-dse-v2" */ '@/components/legal/dse/V2.vue'),
    PolicyV3: () => import(/* webpackChunkName: "l-dse-v3" */ '@/components/legal/dse/V3.vue'),
  },
  meta() {
    return {
      title: this.$t('privacy').toString(),
    };
  },
});
